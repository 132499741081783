import React, {useRef, useEffect}  from 'react';
import { useDispatch } from 'react-redux';
import loadable from '@loadable/component';

import SearchAutocompleteContext from './search-autocomplete-context';
import useHawksearchAutocomplete from  '../useHawksearchAutocomplete';
import { Helpers } from '../../core';

import { showPopover } from '../../page/components/header-footer/header-footer-actions';

import './css/search-autocomplete-container.less';

const SearchSuggestions = loadable(() => Helpers.retryFunc(() => import('./search-suggestions')));
const SearchSuggestionsWithTrendingKeywords = loadable(() => Helpers.retryFunc(() => import('./trending-keywords/search-suggestions')));

export const POPOVER_KEY = 'searchModal';

export default ({ children, ...rest }) => {

    const autocompleteData = useHawksearchAutocomplete({ ...rest || {}, withSupport: true});
    const { 
        closeModalAndInputBar,
        autocompleteModalOpen,
        autocompleteTrendingKeywordsTestEnabled
    } = autocompleteData || {};
    const containerRef = useRef();

    const dispatch = useDispatch();

    const SearchSuggestionsComponent = autocompleteTrendingKeywordsTestEnabled ? SearchSuggestionsWithTrendingKeywords : SearchSuggestions;
    useEffect(() => {
        dispatch(showPopover(autocompleteModalOpen ? POPOVER_KEY : null));
    }, [autocompleteModalOpen]);
    
    /**
     * Add event listener for clicking away from the search box
     */
     useEffect(() => {
        const onSearchClickAway = event => {
            if(containerRef && !containerRef.current.contains(event.target)){
                closeModalAndInputBar();
            }
        };

        document.addEventListener('mousedown', onSearchClickAway);
        return () => {
            document.removeEventListener('mousedown', onSearchClickAway)
        }   
    });

    return (
        <SearchAutocompleteContext.Provider value={autocompleteData}>
            <div className='search-autocomplete-container' ref={containerRef}>
                {children}
                <SearchSuggestionsComponent />
            </div>
        </SearchAutocompleteContext.Provider> 
    );
}